import abilitiesCommon from '@guuru/abilities-common';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ability from '../../../../config/ability';
import useFetchCurrentUser from '../useFetchCurrentUser';
import useFetchCurrentPartner from '../useFetchCurrentPartner';

const setInitialPartner = (partnerId) => {
  localStorage.setItem('selectedPartner', partnerId);
};

const getPreviousPartner = (hasAdminRole, partnerIdsWithRoles) => {
  const localStoragePartner = localStorage.getItem('selectedPartner');
  if (!hasAdminRole) {
    const stillHasAccess = partnerIdsWithRoles?.find(
      (partner) => partner === localStoragePartner,
    );
    if (!stillHasAccess) {
      return null;
    }
  }
  return localStoragePartner;
};

const getInitialPartner = (hasAdminRole, partnerIdsWithRoles) => {
  const previousPartner = getPreviousPartner(hasAdminRole, partnerIdsWithRoles);
  return previousPartner || partnerIdsWithRoles[0] || 'guuru-ag';
};

const useGetAuthContext = (
  user,
  partner,
  signInErrorCode,
  setCurrentPartnerId,
) => {
  const switchPartner = useCallback((id, { navigate, path = '/' } = {}) => {
    setInitialPartner(id);
    setCurrentPartnerId(id);
    if (navigate) {
      navigate(path, { replace: true });
    } else {
      window.location.reload();
    }
  }, [setCurrentPartnerId]);

  const authContext = useMemo(() => ({
    user: {
      ...user,
      can: (...vars) => ability.can(...vars),
    },
    partner,
    switchPartner,
    signInErrorCode,
  }), [user, partner, switchPartner, signInErrorCode]);

  return { authContext };
};

const useGetCurrentPartnerId = (user) => {
  const [currentPartnerId, setCurrentPartnerId] = useState(undefined);
  useEffect(() => {
    if (user.uid) {
      const { hasAdminRole, partnerIdsWithRoles } = user;
      const partnerId = getInitialPartner(hasAdminRole, partnerIdsWithRoles);
      setInitialPartner(partnerId);
      setCurrentPartnerId(partnerId);
    }
  }, [user, currentPartnerId]);
  return { currentPartnerId, setCurrentPartnerId };
};

export default () => {
  const {
    loading: isLoadingUser,
    user,
    signInErrorCode,
  } = useFetchCurrentUser();

  const {
    currentPartnerId,
    setCurrentPartnerId,
  } = useGetCurrentPartnerId(user);

  const {
    loading: isLoadingPartner,
    partner,
  } = useFetchCurrentPartner(currentPartnerId);

  const {
    authContext,
  } = useGetAuthContext(user, partner, signInErrorCode, setCurrentPartnerId);

  const loading = isLoadingUser || isLoadingPartner;
  if (!loading) {
    ability.update(
      abilitiesCommon.forUser(
        user.abilities.globalAbilities,
        user.abilities.partnerAbilities,
        partner.id,
      ).rules,
    );
  }
  return {
    loading,
    user,
    partner,
    authContext,
  };
};
