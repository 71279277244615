import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const isGaEnabled = !!process.env.PARTNERS_GTM_ACCOUNT_ID;
const rolesOrderedByPriority = [
  'superAdmin',
  'engineer',
  'communityManager',
  'salesManager',
  'financeManager',
  'productManager',
  'permissionsManager',
  'salesGlobalAll',
  'superGuuru',
  'admin',
];

export default (partnerId, roles = []) => {
  useEffect(() => {
    if (!roles.length || !partnerId) return;
    if (!isGaEnabled) return;

    const roleName = rolesOrderedByPriority.find((role) => {
      const globalRole = (
        roles.find((userRole) => !userRole.partner && userRole.role === role)
      );

      if (globalRole) {
        return globalRole.role;
      }

      const partnerRole = roles.find((userRole) => (
        userRole.partner?.id === partnerId && userRole.role === role
      ));

      if (partnerRole) {
        return partnerRole.role;
      }

      return false;
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'gtm.partnerChange',
        session: {
          userRole: roleName,
          currentPartnerId: partnerId,
        },
      },

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId, roles, isGaEnabled]);
};
