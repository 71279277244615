import { useQuery } from '@apollo/client';
import find from 'lodash/find';
import { allRoles, noPartnerRoles } from '@guuru/abilities-common';
import { useEffect, useState } from 'react';
import { signOut, auth } from '@guuru/auth-web';
import GET_USER from './queries/getFetchCurrentUser';
import mainMenu
  from '../../../../components/navigation/NavigationMenu/mainMenu';
import ability from '../../../../config/ability';

const getPartnerRoles = (roles, partnerId) => (
  roles
    .filter(({ partner }) => partner?.id === partnerId)
    ?.map(({ role }) => role)
);

const useFirebaseCurrentUserId = () => {
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => auth.onAuthStateChanged(setAuthUser), []);
  return { userId: authUser?.uid, loading: authUser === false };
};

const useCurrentUser = (userId) => {
  const [signInErrorCode, setSignInErrorCode] = useState();
  const {
    loading,
    data: {
      expert: {
        name,
        email,
        photoUrl,
        roles = [],
        abilities = {},
      } = {},
    } = {},
  } = useQuery(GET_USER, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  let hasAdminRole;
  let hasAccess;
  let partnerIdsWithRoles;
  if (!loading && userId) {
    partnerIdsWithRoles = Object.keys(abilities.partnerAbilities || {});
    hasAdminRole = roles.some(
      (r) => allRoles[r.role] || noPartnerRoles[r.role],
    );
    hasAccess = hasAdminRole || partnerIdsWithRoles.length;
    if (!hasAccess) {
      (async () => {
        await signOut({ keepPushToken: true });
      })();
      if (!signInErrorCode) {
        setSignInErrorCode('auth/access-denied');
      }
      localStorage.removeItem('impersonateToken');
    } else if (signInErrorCode) {
      setSignInErrorCode(null);
    }
  }
  const canAccess = (route) => {
    const menu = find(mainMenu, { route });
    if (!menu || !menu.permission) {
      return true;
    }
    return ability.can('read', menu.permission);
  };

  const hasRole = (r, partnerId) => (
    getPartnerRoles(roles, partnerId).includes(r)
  );
  const hasOnlyRole = (r, partnerId) => {
    const partnerRoles = getPartnerRoles(roles, partnerId);
    return (partnerRoles.length === 1 && partnerRoles[0] === r);
  };

  const hasGlobalRole = (r) => (roles.find(({ role }) => r === role));
  const hasGlobalOnlyRole = (r) => (roles.length === 1 && roles[0].role === r);

  return {
    loading,
    signInErrorCode,
    user: {
      uid: (!signInErrorCode && !loading) ? userId : undefined,
      name,
      email,
      photoUrl,
      roles,
      abilities,
      hasAdminRole,
      hasAccess,
      partnerIdsWithRoles,
      isAccountVerified: true,
      isTosAccepted: true,
      isSuperAdmin: roles.some((r) => allRoles[r.role]),
      hasRole,
      hasOnlyRole,
      hasGlobalRole,
      hasGlobalOnlyRole,
      canAccess,
    },
  };
};

export default () => {
  const { userId, loading: isLoadingFirebaseUser } = useFirebaseCurrentUserId();
  const {
    loading: isLoadingUser,
    user,
    signInErrorCode,
  } = useCurrentUser(userId);

  return {
    loading: isLoadingUser || isLoadingFirebaseUser,
    user,
    signInErrorCode,
  };
};
