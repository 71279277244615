import { gql } from '@apollo/client';

export default gql`
  query GetFetchCurrentUser($id: ID!) {
    expert(id: $id) {
      id
      name
      email
      photoUrl
      roles {
        id
        role
        partner {
          id
          name
          status
          settings {
            id
            appearance {
              chatWidgetPhotoUrl
            }
          }
        }
      }
      abilities
    }
  }
`;
