import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ShouldRedirect = function ({
  user = {
    hasRole: () => {},
    hasGlobalOnlyRole: () => {},
  },
  partner,
  children,
}) {
  if (user.hasRole('superGuuru', partner.id)) {
    return <Navigate replace to="/tasks" />;
  }
  if (user.hasGlobalOnlyRole('tagger')) {
    return <Navigate replace to="/admin/automation/predictions" />;
  }
  return children;
};

ShouldRedirect.propTypes = {
  user: PropTypes.shape({
    hasRole: PropTypes.func,
    hasGlobalOnlyRole: PropTypes.func,
  }),
  partner: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default ShouldRedirect;
