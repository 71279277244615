import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectOnLoggedIn = function ({
  session: { uid = null } = {},
  children,
  redirectToPath,
}) {
  if (!uid) return children;
  return (
    <Navigate replace to={redirectToPath} />
  );
};

RedirectOnLoggedIn.propTypes = {
  session: PropTypes.shape({
    uid: PropTypes.string,
  }),
  redirectToPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default RedirectOnLoggedIn;
