import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-card,
  .ant-list,
  .ant-alert,
  .ant-tabs,
  .ant-space-vertical {
    width: 100%;
  }

  .ant-card {
    margin-bottom: 15px;
  }

  .ant-list-item {
    && {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-list-item-meta {
    display: flex;
    align-items: center;

    .ant-list-item-meta-avatar {
      margin-right: 0.75rem;
    }

    .ant-list-item-meta-description {
      color: ${({ theme }) => theme.neutralVariants[3]};
    }
  }

  .ant-card-body {
    .ant-card:not(.ant-card-hoverable) {
      box-shadow: none;
    }
  }

  .ant-card-head {
    .ant-card-head-title {
      padding: 11px 0;
    }
  }

  .ant-drawer-body {
    .ant-card:not(.ant-card-borderless) {
      box-shadow: none;
    }
  }

  .ant-collapse-extra {
    text-align: right;
    color: ${({ theme }) => theme.neutralVariants[3]};
  }

  .ant-space-item:empty {
    display: none;
  }

  .ant-form-item-with-help:not(:last-child) {
    padding-bottom: 24px;
  }
`;

export default GlobalStyle;
