import { createContext } from 'react';

const AuthContext = createContext({
  user: undefined,
  partner: undefined,
  switchPartner: () => {},
  signInErrorCode: undefined,
});

export default AuthContext;
