import { useQuery } from '@apollo/client';
import moment
  from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import GET_PARTNER from './queries/getFetchCurrentPartner';

export default (partnerId) => {
  const {
    loading,
    data: {
      partner,
    } = {},
  } = useQuery(GET_PARTNER, {
    skip: !partnerId,
    variables: {
      id: partnerId,
    },
  });

  return {
    loading,
    partner: {
      ...partner,
      id: partnerId,
      timezone: partner?.timezone || moment.tz.guess(),
      logoUrl: partner?.settings?.appearance?.chatWidgetPhotoUrl,
      isAgentsEnabled: partner?.settings?.agents?.isEnabled,
      isAutomationEnabled: partner?.hasPredictions,
      isMentionsEnabled: partner?.settings?.community?.mentionsEnabled,
      isBotEnabled: partner?.automation?.settings?.isBotEnabled,
      isMonitoringEnabled: partner?.settings?.tasks?.isMonitoringEnabled,
      defaultLanguage: partner?.settings?.appearance?.chatWidgetDefaultLanguage,
      enabledLanguages: partner?.settings?.translations?.enabledLanguages,
    },
  };
};
