import { gql } from '@apollo/client';

export default gql`
  query GetFetchCurrentPartner($id: ID!) {
    partner(id: $id) {
      id
      timezone
      name
      hasPredictions
      industry {
        id
      }
      settings {
        id
        translations {
          enabledLanguages
        }
        appearance {
          chatWidgetPhotoUrl
          chatWidgetDefaultLanguage
        }
        agents {
          isEnabled
        }
        community {
          mentionsEnabled
        }
        tasks {
          isMonitoringEnabled
        }
      }
      automation {
        id
        settings {
          isBotEnabled
        }
      }
      organization {
        id
        name
        partners {
          id
          name
        }
      }
      subscriptions(status: ACTIVE) {
        edges {
          id
          node {
            id
            name
          }
        }
      }
    }
  }
`;
