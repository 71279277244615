import React from 'react';
import { Navigate } from 'react-router-dom';
import { ForbiddenPage } from '@guuru/react-error-pages';
import PropTypes from 'prop-types';
import { gtag } from '@guuru/analytics-web';

const fireTracking = () => {
  gtag.event('page_view', {
    page_path: window.location.pathname + window.location.search,
  });
};

const checkForUpdates = () => {
  const REFRESH_INTERVAL_MS = process.env.REFRESH_INTERVAL_MS
  || (5 * 60 * 1000);
  let lastVersionCheckAt;
  let lastVersionModifiedDate;

  if (
    process.env.NODE_ENV !== 'production'
    || (Date.now() - lastVersionCheckAt) < REFRESH_INTERVAL_MS
  ) {
    return;
  }

  fetch('/', { method: 'HEAD' })
    .then(({ headers, ok }) => {
      if (!ok) {
        throw new Error();
      }
      return headers.get('last-modified');
    })
    .then((lastModifiedHeader) => {
      if (!lastModifiedHeader) return;
      const lastModifiedDate = new Date(lastModifiedHeader).getTime();
      if (lastModifiedDate > lastVersionModifiedDate) {
        window.location.reload();
      }
      lastVersionModifiedDate = lastModifiedDate;
      lastVersionCheckAt = Date.now();
    })
    .catch(() => {});
};

const RequireAuth = function ({
  session: {
    uid = null,
    isAccountVerified = false,
    isTosAccepted = false,
  } = {},
  canView = true,
  children,
}) {
  if (!uid) return <Navigate replace to="/signin" />;

  if (!canView) {
    return (
      <ForbiddenPage />
    );
  }

  if (!isAccountVerified) return <Navigate replace to="/verify" />;

  if (!isTosAccepted) return <Navigate replace to="/acceptTos" />;

  fireTracking();
  checkForUpdates();

  return children;
};

RequireAuth.propTypes = {
  session: PropTypes.shape({
    uid: PropTypes.string,
    isAccountVerified: PropTypes.bool,
    isTosAccepted: PropTypes.bool,
  }),
  canView: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
