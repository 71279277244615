import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RedirectOnLoggedIn, RequireAuth } from '@guuru/react-routing';
import { PageNotFound } from '@guuru/react-error-pages';
import { Sentry } from '@guuru/sentry-web';
import LoadingView from '@guuru/react-loading';
import useGaDimensions from './hooks/useGaDimensions';
import useInitAuth from './hooks/useInitAuth';
import AuthContext from '../../context/authContext';
import ShouldRedirect from './ShouldRedirect';

const loadingView = <LoadingView loadingMessage="Connect your Customers" />;

const lazyLoadComponent = (loader) => {
  const Component = lazy(loader);
  return <Component />;
};

const MainView = function () {
  const {
    loading,
    user,
    partner,
    authContext,
  } = useInitAuth();

  useGaDimensions(partner.id, user.roles);

  if (loading) {
    return (
      <main>
        {loadingView}
      </main>
    );
  }
  Sentry.getCurrentScope()
    .setUser({ email: user.email })
    .setExtra('partnerId', partner.id);

  return (
    <AuthContext.Provider value={authContext}>
      <BrowserRouter>
        <main>
          <React.Suspense fallback={loadingView}>
            <Routes>
              <Route
                path="*"
                element={<PageNotFound />}
              />
              <Route
                path="/signin/forgot-password?"
                element={(
                  <RedirectOnLoggedIn session={user} redirectToPath="/">
                    {lazyLoadComponent(() => import('../login/LoginView'))}
                  </RedirectOnLoggedIn>
                )}
              />
              <Route
                path="/recoveraccount"
                element={lazyLoadComponent(() => import('../recover/RecoverAccount'))}
              />
              <Route
                path="/"
              >
                <Route
                  index
                  element={(
                    <ShouldRedirect user={user} partner={partner}>
                      <RequireAuth session={user} canView={user.canAccess('/')}>
                        {lazyLoadComponent(() => import('../reporting/AdminDashboard'))}
                      </RequireAuth>
                    </ShouldRedirect>
                  )}
                />
                <Route
                  path="tasks"
                  element={(
                    <RequireAuth
                      session={user}
                      canView={user.canAccess('/tasks')}
                    >
                      {lazyLoadComponent(() => import('../tasks'))}
                    </RequireAuth>
                  )}
                />
                <Route
                  path="people/:uid"
                  element={(
                    <RequireAuth
                      session={user}
                      canView={user.canAccess('/people')}
                    >
                      {lazyLoadComponent(() => import('../people/AdminVisitor'))}
                    </RequireAuth>
                  )}
                />
                <Route
                  path="ip/:ip"
                  element={(
                    <RequireAuth session={user} canView={user.canAccess('/ip')}>
                      {lazyLoadComponent(() => import('../AdminIP'))}
                    </RequireAuth>
                  )}
                />
                <Route
                  path="automation"
                  element={(
                    <RequireAuth
                      session={user}
                      canView={user.canAccess('/automation')}
                    >
                      {lazyLoadComponent(() => import('../automation/Automation'))}
                    </RequireAuth>
                  )}
                />
                <Route
                  path="pages"
                  element={(
                    <RequireAuth session={user}>
                      {lazyLoadComponent(() => import('../pages/AdminPages'))}
                    </RequireAuth>
                  )}
                />
                <Route path="community">
                  <Route
                    path="updates"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/community/updates')}
                      >
                        {lazyLoadComponent(() => import('../community/CommunityUpdates'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route
                  path="intent/:intentId"
                  element={(
                    <RequireAuth
                      session={user}
                      canView={user.canAccess('/intent')}
                    >
                      {lazyLoadComponent(() => import('../intent/IntentStatsView'))}
                    </RequireAuth>
                  )}
                />
                <Route path="admin">
                  <Route path="partners">
                    <Route
                      index
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/partners')}
                        >
                          {lazyLoadComponent(() => import('../admin/SuperAdminPartners'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="register"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/partners/register')}
                        >
                          {lazyLoadComponent(() => import('../partners/SuperAdminPartnerRegister'))}
                        </RequireAuth>
                      )}
                    />
                  </Route>
                  <Route
                    path="guurus"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/admin/guurus')}
                      >
                        {lazyLoadComponent(() => import('../admin/SuperAdminGuurus'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="billing"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/admin/billing')}
                      >
                        {lazyLoadComponent(() => import('../admin/SuperAdminBilling'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="interactions"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/admin/interactions')}
                      >
                        {lazyLoadComponent(() => import('../SuperAdminConversations'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="translations"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/admin/translations')}
                      >
                        {lazyLoadComponent(() => import('../admin/SuperAdminTranslations'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="ip-report"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/admin/ip-report')}
                      >
                        {lazyLoadComponent(() => import('../ipreport/SuperAdminIpReport'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="categories"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('admin.categories')}
                      >
                        {lazyLoadComponent(() => import('../admin/categories'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="permissions"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('admin.permissions')}
                      >
                        {lazyLoadComponent(() => import('../admin/permissions'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="news"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('admin.globalNews')}
                      >
                        {lazyLoadComponent(() => import('../admin/news'))}
                      </RequireAuth>
                    )}
                  />
                  <Route path="reporting">
                    <Route
                      path="health"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/reporting/health')}
                        >
                          {lazyLoadComponent(() => import('../admin/reporting/SuperAdminHealth'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="dashboard"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/reporting/dashboard')}
                        >
                          {lazyLoadComponent(() => import('../admin/SuperAdminDashboard'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="monitoring"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/reporting/monitoring')}
                        >
                          {lazyLoadComponent(() => import('../admin/reporting/SuperAdminMonitoring'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="revenue"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('/admin/reporting/revenue')}
                        >
                          {lazyLoadComponent(() => import('../admin/reporting/SuperAdminRevenueReport'))}
                        </RequireAuth>
                      )}
                    />
                  </Route>
                  <Route path="automation">
                    <Route
                      path="intents"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('admin.automation.intents')}
                        >
                          {lazyLoadComponent(() => import('../admin/automation/SuperAdminIntents'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="predict"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('admin.automation.questions')}
                        >
                          {lazyLoadComponent(() => import('../admin/automation/SuperAdminPredict'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="predictions"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('admin.automation.questions')}
                        >
                          {lazyLoadComponent(() => import('../admin/automation/SuperAdminPredictions'))}
                        </RequireAuth>
                      )}
                    />
                    <Route
                      path="topics"
                      element={(
                        <RequireAuth
                          session={user}
                          canView={user.canAccess('admin.automation.topics')}
                        >
                          {lazyLoadComponent(() => import('../admin/automation/SuperAdminTopics'))}
                        </RequireAuth>
                      )}
                    />
                  </Route>
                </Route>
                <Route path="community">
                  <Route
                    path="highlights"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/community/highlights')}
                      >
                        {lazyLoadComponent(() => import('../community/CommunityHighlights'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="settings">
                  <Route
                    path="billing"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/billing')}
                      >
                        {lazyLoadComponent(() => import('../billing/AdminBilling'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="billing/invoices/:year/:month"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/billing')}
                      >
                        {lazyLoadComponent(() => import('../billing/Invoices'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="community"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/community')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsCommunity'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="engagement"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/engagement')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsEngagement'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="general"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/general')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsGeneral'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="members"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/members')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsMembers'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="developer"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/developer')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsDeveloper'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="integrations"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/settings/integrations')}
                      >
                        {lazyLoadComponent(() => import('../settings/AdminSettingsIntegrations'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="experts">
                  <Route
                    index
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/experts')}
                      >
                        {lazyLoadComponent(() => import('../team/AdminTeamExperts'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path=":uid"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/experts')}
                      >
                        {lazyLoadComponent(() => import('../team/Expert'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="interactions">
                  <Route
                    index
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/interactions')}
                      >
                        {lazyLoadComponent(() => import('../AdminConversations'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path=":uid"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/interactions/:uid')}
                      >
                        {lazyLoadComponent(() => import('../conversations/AdminConversation'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route
                  path="content"
                  element={(
                    <RequireAuth
                      session={user}
                      canView={user.canAccess('/content')}
                    >
                      {lazyLoadComponent(() => import('../AdminContent'))}
                    </RequireAuth>
                    )}
                />
                <Route path="building">
                  <Route
                    index
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/building')}
                      >
                        {lazyLoadComponent(() => import('../recruitment'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="progress"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/building/progress')}
                      >
                        {lazyLoadComponent(() => import('../RecruitmentProgress'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="reporting">
                  <Route
                    path="automation"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/reporting/automation')}
                      >
                        {lazyLoadComponent(() => import('../reporting/AdminAutomation'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="custom"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/reporting/custom')}
                      >
                        {lazyLoadComponent(() => import('../reporting/AdminCustom'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="conversion"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/reporting/conversion')}
                      >
                        {lazyLoadComponent(() => import('../reporting/AdminConversion'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="monitoring">
                  <Route
                    index
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/monitoring')}
                      >
                        {lazyLoadComponent(() => import('../monitoring/AdminMonitoring/MonitoringOverview'))}
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path=":startDate/:endDate"
                    element={(
                      <RequireAuth
                        session={user}
                        canView={user.canAccess('/monitoring/:startDate/:endDate')}
                      >
                        {lazyLoadComponent(() => import('../monitoring/AdminMonitoring/MonthMonitoring'))}
                      </RequireAuth>
                    )}
                  />
                </Route>
              </Route>
            </Routes>
          </React.Suspense>
        </main>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default MainView;
